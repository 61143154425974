import React from 'react';
import { FirebaseAuthConsumer } from '@react-firebase/auth';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { getUser } from './redux/user/user.actions';
import LoginPage from './views/login';
import Main from './views/main';
import './App.css';

const App: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <FirebaseAuthConsumer>
      {fb => {
        const { firebase, isSignedIn, user } = fb;
        if (_.isEmpty(firebase)) {
          return <CircularProgress size={20} />;
        }
        if (!isSignedIn) {
          return <LoginPage />;
        }
        dispatch(getUser(user.uid));
        return <Main />;
      }}
    </FirebaseAuthConsumer>
  );
};

export default App;
