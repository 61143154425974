import React from 'react';
import { Button as MuiButton, CircularProgress, makeStyles } from '@material-ui/core';
import styles from './submit-button.styles';

interface ButtonProps {
  title: string;
  formId: string;
  color?: 'primary' | 'secondary' | 'inherit';
  isLoading?: boolean;
}

const useStyles = makeStyles(styles);

const Button: React.FC<ButtonProps> = ({ color = 'primary', ...props }) => {
  const { title, isLoading, formId } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <MuiButton type="submit" disabled={isLoading} variant="contained" color={color} form={formId}>
        {title}
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </MuiButton>
    </div>
  );
};

export default Button;
