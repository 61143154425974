import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      marginTop: '12px',
      marginBottom: '12px'
    }
  });

export default styles;
