import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectSnackbarError } from '../../redux/app/app.selectors';
import { hideSnackbarError } from '../../redux/app/app.actions';
import styles from './snackbar-error.styles';

const useStyles = makeStyles(styles);

const SnackbarError = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarError = useSelector(selectSnackbarError);
  const { t } = useTranslation();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackbarError.show}
      onClose={() => dispatch(hideSnackbarError())}
      autoHideDuration={3000}
    >
      <SnackbarContent
        className={classes.error}
        aria-describedby="error-id"
        message={
          <span id="error-id" className={classes.message}>
            <ErrorIcon />
            {snackbarError.message && t(snackbarError.message)}
          </span>
        }
      />
    </Snackbar>
  );
};

export default SnackbarError;
