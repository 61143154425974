import { IRootStore } from '../rootReducer';
import { ApiStatus, IApp, ISnackbar, ModalStatus } from './app.interfaces';

export const selectIsApiLoading = (identifier: any) => (state: IRootStore): boolean => {
  const { api } = state.app;
  // @ts-ignore
  if (api[identifier] === ApiStatus.LOADING) {
    return true;
  }
  return false;
};

export const selectIsApiSuccessful = (identifier: any) => (state: IRootStore): boolean => {
  const { api } = state.app;
  // @ts-ignore
  if (api[identifier] === ApiStatus.SUCCESS) {
    return true;
  }
  return false;
};

export const selectIsModalOpen = (identifier: any) => (state: IRootStore): boolean => {
  const { modal } = state.app;
  // @ts-ignore
  if (modal[identifier] === ModalStatus.OPEN) {
    return true;
  }
  return false;
};

export const selectSnackbarError = (state: IRootStore): ISnackbar => {
  const {
    snackbar: { error }
  } = state.app;

  return error;
};

export const selectSnackbarSuccess = (state: IRootStore): ISnackbar => {
  const {
    snackbar: { success }
  } = state.app;

  return success;
};

export const selectLastFetch = (identifier: any) => (state: IRootStore): Date | null => {
  const { lastFetch } = state.app;
  // @ts-ignore
  return lastFetch[identifier];
};
