import React, { useEffect } from 'react';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/user/user.selectors';
import { logout } from '../redux/user/user.actions';
import { fetchAccounts } from '../redux/account/account.actions';
import Appbar from '../components/appbar';
import Router from '../router';
import styles from './main.styles';

const useStyles = makeStyles(styles);

const MainView: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const account = useSelector(selectUser);
  const [isDrawerOpen, handleDrawer] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => handleDrawer(!isDrawerOpen);

  const navigate = (route: String) => {
    dispatch(push(`/${route}`));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  return (
    <>
      <Appbar
        isMobile={isMobile}
        handleLogout={handleLogout}
        handleDrawer={toggleDrawer}
        navigate={navigate}
        user={account}
      />
      <div className={isMobile ? classes.content : classes.contentFull}>
        <Router />
      </div>
    </>
  );
};

export default MainView;
