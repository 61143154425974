import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import * as History from 'history';
import getRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const history = History.createBrowserHistory();

let composeEnhancers = compose as any;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage: localStorage
  // blacklist: ['app']
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  ...getRootReducer
});

const preloadedState = {};

composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  preloadedState,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
