import actionCreatorFactory from 'typescript-fsa';
import { ISetLastFetchPayload } from './app.interfaces';

const actionCreator = actionCreatorFactory('APP');

export const setApiReady = actionCreator<string>('API_READY');
export const setApiLoading = actionCreator<string>('API_LOADING');
export const setApiError = actionCreator<string>('API_ERROR');
export const setApiSuccess = actionCreator<string>('API_SUCCESS');
export const setLastFetch = actionCreator<ISetLastFetchPayload>('SET_LAST_FETCH');
export const openModal = actionCreator<string>('MODAL_OPEN');
export const closeModal = actionCreator<string>('MODAL_CLOSE');
export const setSnackbarErrorMessage = actionCreator<string>('SNACKBAR_ERROR_SET_MESSAGE');
export const setSnackbarSuccessMessage = actionCreator<string>('SNACKBAR_SUCCESS_SET_MESSAGE');
export const hideSnackbarError = actionCreator('SNACKBAR_ERROR_HIDE');
export const hideSnackbarSuccess = actionCreator('SNACKBAR_SUCCESS_HIDE');
