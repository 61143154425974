import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import styles from './avatar-placeholder.styles';

interface AvatarProps {
  name?: string;
  size: 'avatarPlaceholder' | 'xs' | 'sm';
}

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#607d8b'
];

const useStyles = makeStyles(styles);

const AvatarPlaceholder: React.FC<AvatarProps> = ({ name = ' ', ...props }) => {
  const { size } = props;
  const classes = useStyles();

  let char = ' ';

  if (name) {
    char = name.charAt(0);
  }

  const color = char.charCodeAt(0) % 9 || 9;

  return (
    <div
      className={cn(classes.avatarPlaceholder, classes[size])}
      style={{ background: colors[color] }}
    >
      <span>{char}</span>
    </div>
  );
};

export default AvatarPlaceholder;
