import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { firebase } from '@firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import './index.css';
import App from './App';
import theme from './theme';
import de from './static/translations/de.json';
import SnackbarSuccess from './components/snackbar-success';
import SnackbarError from './components/snackbar-error';
import { store, persistor, history } from './redux/store';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const config = {
  apiKey: process.env.REACT_APP_API_KEY!,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID!,
  databaseURL: ''
};

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      de: {
        translation: de
      }
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false
    }
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FirebaseAuthProvider {...config} firebase={firebase}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarError />
              <SnackbarSuccess />
              <App />
            </ThemeProvider>
          </ConnectedRouter>
        </FirebaseAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
