import React, { Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const Dashboard = React.lazy(() => import('./views/dashboard'));
const MyAccount = React.lazy(() => import('./views/my-account'));
const Accounts = React.lazy(() => import('./views/accounts'));

const Router: React.FC = () => {
  return (
    <Suspense fallback={<CircularProgress size={20} />}>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/my-account" component={MyAccount} />
        <Route exact path="/accounts" component={Accounts} />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Router);
