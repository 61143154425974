import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../components/submit-button';
import Input from '../../components/input';

interface LoginFormProps {
  onSubmit: (event: any) => void;
  isLoading: boolean;
}
const LoginForm: React.FC<LoginFormProps> = props => {
  const { onSubmit, isLoading } = props;
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="loginForm">
          <FormGroup>
            <Field name="email">
              {({ input, meta }) => (
                <Input meta={meta} input={input} title={t('form.login.email.label')} type="text" />
              )}
            </Field>
          </FormGroup>
          <FormGroup>
            <Field name="password">
              {({ input, meta }) => (
                <Input
                  meta={meta}
                  input={input}
                  title={t('form.login.password.label')}
                  type="password"
                />
              )}
            </Field>
          </FormGroup>
          <Button
            formId="loginForm"
            title={t('form.login.submit')}
            color="primary"
            isLoading={isLoading}
          />
        </form>
      )}
    </Form>
  );
};

LoginForm.defaultProps = {
  isLoading: false
};

export default LoginForm;
