import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    content: {
      marginTop: '72px',
      flexGrow: 1,
      padding: '20px'
    },
    contentFull: {
      marginTop: '72px',
      flexGrow: 1,
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',

      justifyContent: 'flex-start' /* align items in Main Axis */,
      alignItems: 'stretch' /* align items in Cross Axis */,
      alignContent: 'stretch' /* Extra space in Cross Axis */
    }
  });

export default styles;
