import React, { ReactElement } from 'react';
import { TextField, makeStyles, InputAdornment, InputProps } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from './input.styles';

const useStyles = makeStyles(styles);

interface FormInputProps {
  mask?: string;
  title: string;
  type: string;
  endAdornment?: ReactElement | string;
  multiline?: boolean;
  rows?: string;
  input: {
    name: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  };
  meta: {
    touched?: boolean;
    error?: string;
  };
  required?: boolean;
}

const MoneyFormat: React.FC<any> = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      onValueChange={(values: { value: string }): void => {
        if (onChange) {
          const target: Partial<HTMLInputElement> = {
            value: values.value
          };
          onChange({
            target
          });
        }
      }}
    />
  );
};

const FormInput: React.FC<FormInputProps> = props => {
  const {
    title,
    type,
    mask,
    multiline,
    endAdornment,
    rows,
    input: { onChange, value, name },
    meta: { touched, error },
    required
  } = props;
  const classes = useStyles();

  let hasError = false;

  if (touched && error) {
    hasError = true;
  }

  const inputProps: Partial<InputProps> = {};

  if (mask === 'money') {
    inputProps.inputComponent = MoneyFormat;
  }

  if (endAdornment) {
    inputProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
  }

  return (
    <TextField
      onChange={onChange}
      classes={{ root: classes.root }}
      value={value || ''}
      type={type}
      label={title}
      name={name}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      error={hasError}
      helperText={hasError && error}
      required={required}
      InputProps={inputProps}
    />
  );
};

export default FormInput;
