import actionCreatorFactory from 'typescript-fsa';
import { IAccount } from '../../models/account';
import { IEditAccountPayload, ITransactionPayload } from './account.interfaces';

const actionCreator = actionCreatorFactory('ACCOUNT');

export const fetchAccounts = actionCreator('FETCH');
export const getAccounts = actionCreator('GET');
export const deleteAccount = actionCreator<string>('DELETE');
export const setAccounts = actionCreator<IAccount[]>('SET');
export const createAccount = actionCreator<Partial<IAccount>>('CREATE');
export const editAccount = actionCreator<IEditAccountPayload>('EDIT');
export const expense = actionCreator<ITransactionPayload>('EXPENSE');
export const transfer = actionCreator<ITransactionPayload>('TRANSFER');
export const income = actionCreator<ITransactionPayload>('INCOME');
