import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/user/user.actions';
import LoginView from './login.view';

const LoginPage = () => {
  const dispatch = useDispatch();

  const handleLoginForm = (formdata: any) => {
    dispatch(login(formdata));
  };
  return <LoginView handleLoginForm={handleLoginForm} isLoading={false} />;
};

export default LoginPage;
