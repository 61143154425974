import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#43a047'
    },
    secondary: lightBlue
  }
});
