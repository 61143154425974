import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    wrapper: {
      position: 'relative',
      minWidth: 120
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  });

export default styles;
