import React from 'react';
import { Grid, makeStyles, Card, CardContent } from '@material-ui/core';
import LoginForm from './login.form';
import styles from './login.styles';

const useStyles = makeStyles(styles);

interface LoginPageProps {
  handleLoginForm: (event: any) => void;
  isLoading: boolean;
}
const LoginPage: React.FC<LoginPageProps> = props => {
  const classes = useStyles();

  const { handleLoginForm, isLoading } = props;
  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      <Grid item lg={4}>
        <Card>
          <CardContent>
            <LoginForm onSubmit={handleLoginForm} isLoading={isLoading} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

LoginPage.defaultProps = {
  isLoading: false
};

export default LoginPage;
