import { reducerWithInitialState } from 'typescript-fsa-reducers';
import _ from 'lodash';
import { IAccount } from '../../models/account';
import * as Actions from './account.actions';

const initialState: IAccount[] = [];

const accountReducer = reducerWithInitialState(initialState).case(
  Actions.setAccounts,
  (_state, accounts) => {
    return accounts;
  }
);

export default accountReducer;
