import { call, spawn, take, select, put } from 'redux-saga/effects';
import firebase from 'firebase';
import * as Actions from './user.actions';
import { selectUser } from './user.selectors';
import {
  setApiLoading,
  setApiSuccess,
  closeModal,
  setApiError,
  setSnackbarSuccessMessage,
  setSnackbarErrorMessage
} from '../app/app.actions';
import { IUpdateUserPayload } from './user.interfaces';

export function* login(formdata: any) {
  yield firebase.auth().signInWithEmailAndPassword(formdata.email, formdata.password);
}

export function* logout() {
  yield put(Actions.resetUser());
  yield firebase.auth().signOut();
}

export function* refreshToken() {
  const token = yield firebase.auth().currentUser?.getIdToken();

  yield put(
    Actions.setUser({
      token
    })
  );
}

export function* getUser(id: string) {
  const localAccount = yield select(selectUser);
  if (!localAccount.uid) {
    const token = yield firebase.auth().currentUser?.getIdToken();

    yield put(
      Actions.setUser({
        uid: id,
        email: firebase.auth().currentUser!.email!,
        token,
        displayName: firebase.auth().currentUser!.displayName!
      })
    );
  } else {
    yield put(Actions.refreshToken());
  }
}

export function* updateAccount(payload: any) {
  yield put(setApiLoading('updateAccount'));

  const localAccount = yield select(selectUser);

  yield firebase.auth().currentUser?.updateProfile({ displayName: payload.displayName });

  const firebaseAccount = {
    displayName: firebase.auth().currentUser!.displayName!,
    email: firebase.auth().currentUser!.email!
  };

  yield put(Actions.setUser({ ...localAccount, ...firebaseAccount }));
  yield put(setApiSuccess('updateAccount'));
  yield put(setSnackbarSuccessMessage('api.success.updateAccount'));
  yield put(closeModal('updateAccount'));
}

export function* changePassword(payload: IUpdateUserPayload) {
  yield put(setApiLoading('updateAccount'));
  try {
    const { password, passwordNew } = payload;
    const account = yield select(selectUser);
    yield login({ email: account.email, password });

    yield firebase.auth().currentUser?.updatePassword(passwordNew!);

    yield put(setApiSuccess('updateAccount'));
    yield put(setSnackbarSuccessMessage('api.success.changePassword'));
    yield put(closeModal('updateAccount'));
  } catch (err) {
    yield put(setApiError('updateAccount'));
    yield put(setSnackbarErrorMessage('api.error.changePassword'));
  }
}

export function* watchLogin() {
  while (true) {
    const { payload } = yield take(Actions.login);

    yield call(login, payload);
  }
}

export function* watchLogout() {
  while (true) {
    yield take(Actions.logout);
    yield call(logout);
  }
}

export function* watchGetAccount() {
  while (true) {
    const { payload } = yield take(Actions.getUser);

    yield call(getUser, payload);
  }
}

export function* watchUpdateAccount() {
  while (true) {
    const { payload } = yield take(Actions.updateUser);

    yield call(updateAccount, payload);
  }
}

export function* watchChangePassword() {
  while (true) {
    const { payload } = yield take(Actions.changePassword);

    yield call(changePassword, payload);
  }
}

export function* watchRefreshToken() {
  while (true) {
    yield take(Actions.refreshToken);
    yield call(refreshToken);
  }
}
export default [
  spawn(watchLogin),
  spawn(watchLogout),
  spawn(watchGetAccount),
  spawn(watchUpdateAccount),
  spawn(watchChangePassword),
  spawn(watchRefreshToken)
];
