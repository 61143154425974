import actionCreatorFactory from 'typescript-fsa';
import { IUser } from '../../models/user';
import { IUpdateUserPayload, ILoginPayload } from './user.interfaces';

const actionCreator = actionCreatorFactory('USER');

export const login = actionCreator<ILoginPayload>('LOGIN');
export const logout = actionCreator('LOGOUT');
export const getUser = actionCreator<string>('GET');
export const setUser = actionCreator<Partial<IUser>>('SET');
export const resetUser = actionCreator('RESET');
export const updateUser = actionCreator<IUpdateUserPayload>('UPDATE');
export const changePassword = actionCreator<IUpdateUserPayload>('CHANGE_PASSWORD');
export const refreshToken = actionCreator('REFRESH_TOKEN');
