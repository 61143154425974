import { IAccount } from '../models/account';
import accountReducer from './account/account.reducers';
import userReducer from './user/user.reducers';
import appReducer from './app/app.reducers';
import { IApp } from './app/app.interfaces';
import { IUser } from '../models/user';

const reducers = {
  app: appReducer,
  account: accountReducer,
  user: userReducer
};

export default reducers;

export interface IRootStore {
  app: IApp;
  account: IAccount[];
  user: IUser;
}
