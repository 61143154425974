import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    error: {
      backgroundColor: '#D32F2F'
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  });

export default styles;
