import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Actions from './app.actions';
import { IApp, ApiStatus, ModalStatus } from './app.interfaces';

const initialState: IApp = {
  modal: {
    updateUser: ModalStatus.CLOSED,
    createAccount: ModalStatus.CLOSED,
    editAccount: ModalStatus.CLOSED,
    deleteAccount: ModalStatus.CLOSED,
    addTransaction: ModalStatus.CLOSED
  },
  api: {
    getAccounts: ApiStatus.READY,
    updateUser: ApiStatus.READY,
    createAccount: ApiStatus.READY,
    editAccount: ApiStatus.READY,
    deleteAccount: ApiStatus.READY,
    addTransaction: ApiStatus.READY
  },
  lastFetch: {
    getAccounts: null
  },
  snackbar: {
    error: {
      show: false,
      message: ''
    },
    success: {
      show: false,
      message: ''
    }
  }
};

const appReducer = reducerWithInitialState(initialState)
  .case(Actions.setApiReady, (state, identifier) => ({
    ...state,
    api: {
      ...state.api,
      [identifier]: ApiStatus.READY
    }
  }))
  .case(Actions.setApiLoading, (state, identifier) => ({
    ...state,
    api: {
      ...state.api,
      [identifier]: ApiStatus.LOADING
    }
  }))
  .case(Actions.setApiError, (state, identifier) => ({
    ...state,
    api: {
      ...state.api,
      [identifier]: ApiStatus.ERROR
    }
  }))
  .case(Actions.setApiSuccess, (state, identifier) => ({
    ...state,
    api: {
      ...state.api,
      [identifier]: ApiStatus.SUCCESS
    }
  }))
  .case(Actions.openModal, (state, identifier) => ({
    ...state,
    modal: {
      ...state.modal,
      [identifier]: ModalStatus.OPEN
    }
  }))
  .case(Actions.closeModal, (state, identifier) => ({
    ...state,
    modal: {
      ...state.modal,
      [identifier]: ModalStatus.CLOSED
    }
  }))
  .case(Actions.setSnackbarErrorMessage, (state, message) => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      error: {
        ...state.snackbar.error,
        show: true,
        message
      }
    }
  }))
  .case(Actions.setSnackbarSuccessMessage, (state, message) => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      success: {
        ...state.snackbar.success,
        show: true,
        message
      }
    }
  }))
  .case(Actions.hideSnackbarError, state => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      error: {
        ...state.snackbar.error,
        show: false
      }
    }
  }))
  .case(Actions.hideSnackbarSuccess, state => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      success: {
        ...state.snackbar.success,
        show: false
      }
    }
  }))
  .case(Actions.setLastFetch, (state, payload) => {
    const { date, identifier } = payload;
    return {
      ...state,
      lastFetch: {
        ...state.lastFetch,
        [identifier]: date
      }
    };
  });
export default appReducer;
