import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    success: {
      backgroundColor: '#43a047'
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  });

export default styles;
