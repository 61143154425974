import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Link,
  LinearProgress
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AvatarPlaceholder from '../avatar-placeholder';
import styles from './appbar.styles';
import { IUser } from '../../models/user';
import { selectIsApiLoading } from '../../redux/app/app.selectors';

const useStyles = makeStyles(styles);

interface IMenuAppBar {
  isMobile: boolean;
  handleLogout: Function;
  handleDrawer: (event: MouseEvent<HTMLButtonElement>) => void;
  user?: IUser;
  navigate: Function;
}
const MenuAppBar: React.FC<IMenuAppBar> = props => {
  const classes = useStyles();
  const isFetchingAccounts = useSelector(selectIsApiLoading('getAccounts'));
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const { isMobile, handleDrawer, user, navigate } = props;

  const handleMenu = (event: React.SyntheticEvent): void => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutUser = () => {
    const { handleLogout } = props;
    handleLogout();
    handleClose();
  };

  return (
    <AppBar position="fixed" className={classes.appBarFull} color="default">
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Link underline="none" href="#" className={classes.title} onClick={() => navigate('')}>
          <Typography variant="h6" color="primary">
            {t('appbar.title')}
          </Typography>
        </Link>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AvatarPlaceholder size="xs" name={user?.displayName} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate('accounts');
                handleClose();
              }}
            >
              {t('appbar.accounts')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('my-account');
                handleClose();
              }}
            >
              {t('appbar.myAccount')}
            </MenuItem>
            <MenuItem onClick={handleLogoutUser}>{t('appbar.signOut')}</MenuItem>
          </Menu>
        </div>
      </Toolbar>
      {isFetchingAccounts && <LinearProgress />}
    </AppBar>
  );
};

export default MenuAppBar;
