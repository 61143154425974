export enum ApiStatus {
  READY = 'READY',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  ERROR = 'ERROR'
}

export enum ModalStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface ISnackbar {
  show: boolean;
  message: string;
}

export interface IApp {
  api: {
    updateUser: ApiStatus;
    getAccounts: ApiStatus;
    createAccount: ApiStatus;
    editAccount: ApiStatus;
    addTransaction: ApiStatus;
    deleteAccount: ApiStatus;
  };
  modal: {
    updateUser: ModalStatus;
    createAccount: ModalStatus;
    editAccount: ModalStatus;
    deleteAccount: ModalStatus;
    addTransaction: ModalStatus;
  };
  lastFetch: {
    getAccounts: Date | null;
  };
  snackbar: {
    error: ISnackbar;
    success: ISnackbar;
  };
}

export interface ISetLastFetchPayload {
  date: Date;
  identifier: string;
}
