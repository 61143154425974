import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectSnackbarSuccess } from '../../redux/app/app.selectors';
import { hideSnackbarSuccess } from '../../redux/app/app.actions';
import styles from './snackbar-success.styles';

const useStyles = makeStyles(styles);

const SnackbarSuccess = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarSuccess = useSelector(selectSnackbarSuccess);
  const { t } = useTranslation();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackbarSuccess.show}
      onClose={() => dispatch(hideSnackbarSuccess())}
      autoHideDuration={3000}
    >
      <SnackbarContent
        className={classes.success}
        aria-describedby="success-id"
        message={
          <span id="success-id" className={classes.message}>
            <CheckCircleIcon />
            {snackbarSuccess.message && t(snackbarSuccess.message)}
          </span>
        }
      />
    </Snackbar>
  );
};

export default SnackbarSuccess;
