import { call, spawn, take, put, select } from 'redux-saga/effects';
import firebase from 'firebase';
import { setApiLoading, setApiSuccess, setApiError, closeModal } from '../app/app.actions';
import * as Actions from './account.actions';
import { refreshToken } from '../user/user.actions';
import { IEditAccountPayload, ITransactionPayload } from './account.interfaces';
import { selectAccounts } from './account.selectors';

function* fetchAll() {
  const db = yield firebase.firestore();
  yield put(setApiLoading('getAccounts'));
  yield put(refreshToken());

  const result = yield db.collection('accounts').get();

  const accounts = result.docs.map((doc: any) => {
    const data = doc.data();
    return {
      uid: doc.id,
      ...data
    };
  });

  yield put(Actions.setAccounts(accounts));
  yield put(setApiSuccess('getAccounts'));
}

function* deleteAccount(payload) {
  const db = yield firebase.firestore();
  yield put(setApiLoading('deleteAccount'));
  yield put(refreshToken());

  yield db.collection('accounts').doc(payload).delete();

  yield put(setApiSuccess('deleteAccount'));
  yield put(closeModal('deleteAccount'));

  yield call(fetchAll);
}

function* create(payload) {
  const db = yield firebase.firestore();
  yield put(setApiLoading('createAccount'));
  yield put(refreshToken());

  yield db.collection('accounts').add({
    lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    transactions: [],
    ...payload
  });

  yield put(setApiSuccess('createAccount'));

  yield call(fetchAll);
  yield put(closeModal('createAccount'));
}

function* edit(payload: IEditAccountPayload) {
  const { uid, account } = payload;
  const db = yield firebase.firestore();
  yield put(setApiLoading('editAccount'));
  yield put(refreshToken());

  yield db
    .collection('accounts')
    .doc(uid)
    .set({ ...account }, { merge: true });

  yield put(setApiSuccess('editAccount'));

  yield call(fetchAll);
  yield put(closeModal('editAccount'));
}

function* expense(payload: ITransactionPayload) {
  yield put(setApiLoading('addTransaction'));
  const { sourceAccount, subject, amount, comment = '' } = payload;

  const accounts = yield select(selectAccounts);
  const sAccount = { ...accounts.find(x => x.uid === sourceAccount) };

  if (!sAccount.transactions) {
    sAccount.transactions = [];
  }

  sAccount.transactions.push({
    subject,
    amount: amount * -1,
    comment,
    valuta: Date.now()
  });

  sAccount.balance = +sAccount.balance - +amount;

  const db = yield firebase.firestore();
  yield db
    .collection('accounts')
    .doc(sAccount.uid)
    .set(
      { lastUpdated: firebase.firestore.FieldValue.serverTimestamp(), ...sAccount },
      { merge: true }
    );

  yield put(setApiSuccess('addTransaction'));

  yield call(fetchAll);
  yield put(closeModal('addTransaction'));
}

function* transfer(payload: ITransactionPayload) {
  yield put(setApiLoading('addTransaction'));
  const { sourceAccount, targetAccount, subject, amount, comment = '' } = payload;

  const accounts = yield select(selectAccounts);
  const sAccount = { ...accounts.find(x => x.uid === sourceAccount) };
  const tAccount = { ...accounts.find(x => x.uid === targetAccount) };

  if (!sAccount.transactions) {
    sAccount.transactions = [];
  }

  if (!tAccount.transactions) {
    tAccount.transactions = [];
  }

  sAccount.transactions.push({
    subject,
    amount: amount * -1,
    comment,
    valuta: Date.now()
  });

  tAccount.transactions.push({
    subject,
    amount,
    comment,
    valuta: Date.now()
  });

  sAccount.balance = +sAccount.balance - +amount;
  tAccount.balance = +tAccount.balance + +amount;

  const db = yield firebase.firestore();
  yield db
    .collection('accounts')
    .doc(sAccount.uid)
    .set(
      { lastUpdated: firebase.firestore.FieldValue.serverTimestamp(), ...sAccount },
      { merge: true }
    );

  yield db
    .collection('accounts')
    .doc(tAccount.uid)
    .set(
      { lastUpdated: firebase.firestore.FieldValue.serverTimestamp(), ...tAccount },
      { merge: true }
    );

  yield put(setApiSuccess('addTransaction'));

  yield call(fetchAll);
  yield put(closeModal('addTransaction'));
}

function* income(payload: ITransactionPayload) {
  yield put(setApiLoading('addTransaction'));
  const { targetAccount, subject, amount, comment = '' } = payload;

  const accounts = yield select(selectAccounts);
  const tAccount = { ...accounts.find(x => x.uid === targetAccount) };

  if (!tAccount.transactions) {
    tAccount.transactions = [];
  }

  tAccount.transactions.push({
    subject,
    amount,
    comment,
    valuta: Date.now()
  });

  tAccount.balance = +tAccount.balance + +amount;

  const db = yield firebase.firestore();
  yield db
    .collection('accounts')
    .doc(tAccount.uid)
    .set(
      { lastUpdated: firebase.firestore.FieldValue.serverTimestamp(), ...tAccount },
      { merge: true }
    );

  yield put(setApiSuccess('addTransaction'));

  yield call(fetchAll);
  yield put(closeModal('addTransaction'));
}

export function* watchFetchAll() {
  while (true) {
    yield take(Actions.fetchAccounts);
    yield call(fetchAll);
  }
}

export function* watchCreate() {
  while (true) {
    const { payload } = yield take(Actions.createAccount);

    yield call(create, payload);
  }
}

export function* watchEdit() {
  while (true) {
    const { payload } = yield take(Actions.editAccount);

    yield call(edit, payload);
  }
}

export function* watchDeleteAccount() {
  while (true) {
    const { payload } = yield take(Actions.deleteAccount);

    yield call(deleteAccount, payload);
  }
}

export function* watchExpense() {
  while (true) {
    const { payload } = yield take(Actions.expense);

    yield call(expense, payload);
  }
}

export function* watchTransfer() {
  while (true) {
    const { payload } = yield take(Actions.transfer);

    yield call(transfer, payload);
  }
}

export function* watchIncome() {
  while (true) {
    const { payload } = yield take(Actions.income);

    yield call(income, payload);
  }
}

export default [
  spawn(watchFetchAll),
  spawn(watchCreate),
  spawn(watchDeleteAccount),
  spawn(watchEdit),
  spawn(watchExpense),
  spawn(watchTransfer),
  spawn(watchIncome)
];
