import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IUser } from '../../models/user';
import * as Actions from './user.actions';

const initialState: IUser = {
  uid: '',
  email: '',
  displayName: '',
  token: ''
};

const userReducer = reducerWithInitialState(initialState)
  .case(Actions.setUser, (state, user) => ({
    ...state,
    ...user
  }))
  .case(Actions.resetUser, () => ({
    ...initialState
  }));
export default userReducer;
