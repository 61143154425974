import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    container: {
      height: '100%'
    }
  });

export default styles;
