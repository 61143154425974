import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    avatarPlaceholder: {
      borderRadius: '100px',
      width: '200px',
      height: '200px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        color: '#ffffff',
        display: 'block',
        fontSize: '96px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none'
      }
    },
    sm: {
      width: '100px !important',
      height: '100px !important',
      '& span': {
        fontSize: '46px !important'
      }
    },
    xs: {
      width: '48px !important',
      height: '48px !important',
      '& span': {
        fontSize: '20px !important'
      }
    }
  });

export default styles;
