import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    appBarFull: {
      width: '100%',
      alignSelf: 'flex-start',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: '100%'
      }
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  });

export default styles;
